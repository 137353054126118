import Layout from "./Layout"
import pic from "./assets/img/illu/partneripic.png"; 

const GDPR = () => {
    return (
        <Layout nested={1} className="base gdpr"> 
            <div className="base__header smaller">
                <div className="base__header__right">
                    <h2>Podmínky ochrany osobních údajů
                    </h2>
                </div>
            </div>

            <ol type="I">
                <li><h3>Základní ustanovení</h3></li>
                <ol>
                    <li>Správcem osobních údajů:
                        podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob
                        souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů
                        (dále jen: „GDPR”) je
                        Kreativní Praha, z. ú., se sídlem Staroměstské náměstí 4/1, Staré Město, 110 00, Praha 1
                        (dále jen: „správce“).</li>
                    <li>Zpracovatel osobních údajů:
                            Kreativní Praha, z. ú., IČO 094 834 89, se sídlem Staroměstské náměstí 4/1, 110 00 Praha 1
                            (dále jen “správce”).
                            Osobní údaje je oprávněn také zpracovávat kterýkoliv zaměstnanec správce, kontaktní email: info@kreativnipraha.eu.</li>
                </ol>


                <li><h3>Zdroje a kategorie zpracovávaných osobních údajů</h3></li>            
                <ol>
                    <li>Správce zpracovává osobní údaje, které jste mu poskytl(a) a/nebo osobní údaje, které správce získal
                    na základě vyplnění dotazníkového formuláře.
                    </li>
                    <li>Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro možnost odpovědi na
                    zaslaný dotaz.</li>
                    <li>Správce zpracovává Vaše údaje pro vyhodnocení a analyzování za účelem zkvalitňování služeb.</li>
                </ol> 

                <li><h3>Zákonný důvod a účel zpracování osobních údajů</h3></li>            
                <ol>
                    <li>Zákonným důvodem zpracování osobních údajů je plnění smlouvy mezi Vámi a správcem podle čl. 6
                    odst. 1 písm. b) GDPR.
                    </li>
                    <li>Účelem zpracování osobních údajů je vyřízení vašeho dotazu e a výkon práv a povinností
                        vyplývajících ze smluvního vztahu mezi Vámi a správcem; při vyplnění formuláře jsou vyžadovány
                        osobní údaje, které jsou nutné pro úspěšné odeslání formuláře (jméno, kontakt), poskytnutí osobních
                        údajů je nutným požadavkem pro odeslání dotazu.</li>
                    <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22. GDPR.</li>
                </ol> 


                <li><h3>Doba uchovávání údajů</h3></li>
                <ol>
                    <li>Správce uchovává osobní údaje po dobu nezbytnou k výkonu práv a povinností vyplývajících ze
                    smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů.
                    </li>
                    <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.</li> 
                </ol> 


                <li><h3>Příjemci osobních údajů (subdodavatelé správce)</h3></li>
                <ol>
                    <li>Příjemci osobních údajů jsou osoby podílející se na dodání služeb na základě smlouvy.</li>
                    <li>Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní
                    organizaci.</li>
                </ol> 


                <li><h3>Vaše práva</h3></li>
                <ol>
                    <li>Za podmínek stanovených v GDPR máte</li>
                    <ol type="a">
                        <li>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR,</li>
                        <li>právo na výmaz osobních údajů dle čl. 17 GDPR,</li>
                        <li>právo vznést námitku proti zpracování dle čl. 21 GDPR,</li>
                        <li>právo na přenositelnost údajů dle čl. 20 GDPR,</li>
                        <li>právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. III těchto podmínek.</li>
                    </ol> 
                    <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že
                    bylo porušeno Vaše právo na ochranu osobních údajů</li>
                </ol> 


                <li><h3>Podmínky zabezpečení osobních údajů</h3></li>
                <ol>
                    <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení
                    osobních údajů.</li> 
                    <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné
                    podobě.</li>
                    <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.
                    </li>
                </ol> 


                <li><h3>Závěrečná ustanovení</h3></li>
                <ol>
                    <li>Odesláním rezervace z internetového formuláře potvrzujete, že jste seznámen(a) s podmínkami
                    ochrany osobních údajů a že je v celém rozsahu přijímáte.</li> 
                    <li>S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře.
                    Zaškrtnutím souhlasu potvrzujete, že jste seznámen(a) s podmínkami ochrany osobních údajů, a že je
                    v celém rozsahu přijímáte.
                    </li>
                </ol> 

            </ol>
            


            <span>Tyto podmínky nabývají účinnosti dnem 1. 7. 2024</span>

        </Layout>
    )
}

export default GDPR