import { ReactLenis } from "@studio-freight/react-lenis";
import { createContext, useEffect, useState } from "react";
import { initCookieConsent } from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";

import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./Home";
import Terminy from "./Terminy";
import Partneri from "./Partneri";
import HashScroll from "./components/UI/HashScroll";
import Detail from "./Detail";
import Media from "./Media";
import Kontakty from "./Kontakty";
import Zastity from "./Zastity";
import GDPR from "./GDPR";
import Vzdelavani from "./Vzdelavani";

const App = () => {
    useEffect(() => {
        initCookieConsent();
    });

    return (
        <ReactLenis root>
            <ToastProvider>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        {/* <Route path="/udalosti" element={<Terminy />} />  
                        <Route path="/o-festivalu" element={<Festival />} />
                         <Route path="/udalosti/:id" element={<Detail />} />*/}
                        <Route path="/akteri" element={<Partneri />} /> 
                        <Route path="/zastity" element={<Zastity />} />
                        <Route path="/vzdelavani" element={<Vzdelavani />} /> 
                        <Route path="/media" element={<Media />} />
                        <Route path="/kontakty" element={<Kontakty />} />
                        <Route path="/gdpr" element={<GDPR />} /> 
                        <Route path="*" element={<Navigate to='/' />} />
                    </Routes>
                <HashScroll />
            </ToastProvider>

            <span id="statusUpdate">f54EcQEMDHrvSR2zPvEN7krfbabRdQULyzeNisw2jHtBoC7Dam6cFQyWPbp2nBjcJJPgwvcQAnNFu6tfXrJkA6Afx25uvBWNM3PgneCGU3dwgxTjtWX2MhJYAy5dWn6E</span>
        </ReactLenis>
    );
};

export default App;
