import {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

//https://react-select.com/

//TODO - vyřešit asynchronní načítání value
const Dropdown = ({
                      className = '',
                      name,
                      label,
                      required,
                      defaultValue,
                      styles,
                      single,
                      options = [],
                      multiValueStyle,
                      isSearchable,
                      ...rest
                  }) => {
    const [value, setValue] = useState(defaultValue);
    const methods = useFormContext();
    const animatedComponents = makeAnimated();

    const onChange = (selectedValues) => {
        setValue(selectedValues)
        if (methods) {
            methods.setValue(name, selectedValues)
        } else if (rest.onChange) {
            rest.onChange(selectedValues);
        }

    }

    const stylesObj = {
        control: (style, {isFocused}) => ({
            ...style,
            borderBottom: isFocused ? "1px solid white" : "1px solid lightgray",
            borderRadius: 0,
            backgroundColor: "#0B4697",
            color: "white",
            "&:hover": {
                borderColor: "gray",
                cursor: "pointer",
            },
        }),
        placeholder: (style) => ({
            ...style,
            color: "white",
            paddingLeft: "0.25rem",
            paddingTop: "0.125rem",
        }),
        input: (style) => ({
            ...style,
            paddingLeft: "0.25rem",
            paddingTop: "0.125rem",
        }),
        valueContainer: (style) => ({
            ...style,
            padding: "0.5rem",
            gap: "0.25rem",
        }),
        singleValue: (style) => ({
            ...style,
            lineHeight: "1.75rem",
            marginLeft: "0.25rem",
        }),
        indicatorsContainer: (style) => ({
            ...style,
            padding: "0.25rem",
            gap: "0.25rem",
        }),
        indicatorSeparator: (style) => ({
            ...style,
            display: "none",
        }),
        menu: (style) => ({
            ...style,
            padding: "0.25rem",
            borderBottom: "1px solid gray",
            backgroundColor: "#0B4697",
            color: "white",
            borderRadius: 0,
            "&:hover": {
                color: "white",
            },
        }),
        option: (style,{ isFocused, isSelected }) => ({
            ...style,
            cursor: "pointer",
            color: "white",
            padding: '0.5rem 0.75rem',
            "&:hover": {
                backgroundColor: "#0079C1",
                color: "white",
            },
        }),
        ...styles
    }

    return (
        <div className={'superdropdown ' + className}>
            {label && <p>{label} {required && <sup>*</sup>}</p>}
            <Select
                options={options}
                {...methods && methods.register(name)}
                {...rest}
                value={value}
                defaultValue
                name={name}
                unstyled
                styles={stylesObj}
                components={animatedComponents}
                isSearchable={isSearchable}
                onChange={onChange}
                placeholder={"Vyberte kategorii"}
            />
            {methods && methods.formState.errors[name] && !single &&
                <p className='err'>{methods.formState.errors[name]?.message}</p>}
        </div>
    )
}

export default Dropdown;

