import { Helmet } from "react-helmet";
import Layout from "./Layout";
import React, { useState } from "react";
import axios from "axios";
import { APIURL } from ".";
import Event from "./components/Event";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import intropic from "./assets/img/illu/intropic.png";
import mapapic from "./assets/img/illu/mapapic.png";
import mapapic2 from "./assets/img/illu/mapapic2.png";
import partneripic from "./assets/img/illu/partneripic.png";
import partneripic2 from "./assets/img/illu/partneripic2.png";
import Bublina from "./components/UI/Bublina";
import Modal from "./components/UI/Modal";

const Home = () => {
    const [eventsData, setEventsData] = useState(null);

    const getEvents = async () => {
        await axios
            .post(APIURL + "/events", {
                type: 'event',
                orderAsc: "ASC",
                limit: 5,
                page: 1,
                filter: [],
            })
            .then((response) => {
                setEventsData(response.data.body);
            });
    };

    useEffect(() => {
        //getEvents();
    }, []);
    return (
        <Layout className="home">
            <Helmet>
                <title>PoznejVltavu</title>
            </Helmet>
            <section className="intro" id="intro">
               <h1>
                    Festival
                    Poznej Vltavu
                    pluje dál!
                </h1>
                <p>Odložená premiéra pražského festivalu se odehraje už v neděli 28. září 2025. Společně užijeme jak původní program pro rok 2024, 
                    tak i moře novinek. Síly totiž spojíme s českými skauty a jejich každoročním závodem 3 jezy. Přijďte si s námi i vy připomenout 
                    Světový den řek a vše, co nám Vltava dává. Slibujeme, že to bude jízda!</p>

                <div className="img">
                    <Bublina type={4}>28.9.2025</Bublina>
                    <img src={intropic} className="pic" />
                </div>
            </section>
            <section className="events" id="udalosti">
                <div className="events__box">
                    <div className="orange">
                        <span>Program festivalu se připravuje.</span> 
                    </div>
                    {/*<div className="events__container">
                        {eventsData?.events?.map((event, i) => (
                            <React.Fragment key={i}>
                                <Event event={event} />
                            </React.Fragment>
                        ))}
                    </div>
                    <Link to="/udalosti" className="button transWhite">
                        zobrazit události
                    </Link>*/}
                </div>
            </section>
             {/*<section id="mapa" className="basic festival">
                <h2>Mapa festivalu</h2>
                <p>
                    Objevte tu správnou aktivitu právě pro vás! Všechny události
                    napříč Prahou najdete v oficiální mapě festivalu. Stáhněte
                    si ji do mobilu a v den konání už vás nic nezaskočí.{" "}
                </p>
                <Link
                    className="button transWhite"
                    target="_blank"
                    to="/mapa_poznejVltavu.webp"
                >
                    stáhnout mapu
                </Link>
                <div className="img mapa">
                    <Bublina type={2}>
                        Zážitkový festival
                        <br />
                        pro celou rodinu
                    </Bublina>
                    <img className="pic" src={mapapic} />
                </div>
                <img src={mapapic2} className="zamek" />
            </section>>*/}
            <section id="partneri" className="basic akteri">
                <h2>Aktéři</h2>
                <p>
                    Vltava má své příznivce napříč celým městem. Prohlédněte si
                    seznam zapojených firem, institucí a osobností, díky kterým
                    práská festivalový program ve švech.{" "}
                </p>
                <Link className="button transWhite" to="/akteri">
                    Zobrazit aktéry
                </Link>
                <div className="pics">
                    <img src={partneripic} className="left" />
                    <div className="img">
                        <Bublina type={3}>
                            Jedna řeka
                            <br />
                            Stokrát jinak
                        </Bublina>
                        <img className="pic" src={partneripic2} />
                    </div>
                </div>
            </section>

            <Modal state={[false,()=>{}]}>
                <img src={mapapic2} />
                <div className="boxik">
                    <h1>Festival Poznej Vltavu pluje dál! </h1>
                    <p>Odložená premiéra pražského festivalu se odehraje už v neděli 28. září 2025. Společně užijeme jak původní program pro rok 2024, tak i moře novinek. Síly totiž spojíme s českými skauty a jejich každoročním závodem 3 jezy. Přijďte si s námi i vy připomenout Světový den řek a vše, co nám Vltava dává. Slibujeme, že to bude jízda!</p> 
                </div>
            </Modal>
        </Layout>
    );
};

export default Home;
