import axios from "axios";
import Layout from "./Layout"
import pic from "./assets/img/illu/partneripic.png";
import { APIURL, STORAGEURL } from ".";
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import ReactPlayer from 'react-player';
import Loading from "./components/UI/Loading";


const Media = () => {
    const [srcs, setSrcs] = useState(null);
    const [slide, setSlide] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    const [pageData, setPageData] = useState()
    const [pageDataLoading, setPageDataLoading] = useState(true)
    const fetchPage = async () => {
        await axios
            .post(APIURL + "/page", {
                name: 'press',
                language: "cs",
            })
            .then((response) => {  
                setSrcs(response.data.body?.galerie.includes("~")&& response.data.body?.galerie.split("~")?.filter((i) => (i.length>0?true:false)).map((i)=>(STORAGEURL+i)));
                setPageData(response.data.body);
                setPageDataLoading(false);
              });
    }

    useEffect(() => {
        fetchPage()
    }, [])


    return (
        <Layout nested={1} className="base kontakt">
            {(pageDataLoading) ? <Loading /> : <>
            <img className="picmedia" src={pic} alt="illustration" />
            <div className="base__header smaller">
                <div className="base__header__right">
                    <h2>Press</h2>
                </div>
            </div>
            <h3>PR a komunikace s médii</h3>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <strong>Naďa Šeráková</strong>
                    <a href='mailto:festival@kreativnipraha.eu' className="inline">nada@serakovan.cz</a>
                </div>
                <div className="kontakty__kontakt">
                    <span>Vaše dotazy či výzvy směřujte na email a my Vám rádi odpovíme: <a href='mailto:festival@kreativnipraha.eu' className="inline">festival@kreativnipraha.eu</a></span>
                </div>
            </div>
            <h3>Ke stažení</h3>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <a href="Poznej Vltavu_loga.zip" target='_blank'>Loga</a>
                    <a href="Poznej Vltavu_vizual.pdf" target='_blank'>Vizuál</a>
                </div>
            </div>

            {pageData.galerie&&<>
                <h3>Média</h3>
                <div className="kontakt__gallery">
                    {pageData?.galerie.split("~")?.filter((i) => (i.length>0?true:false)).map((file, i) =>(
                        <div key={i} className={"kontakt__gallery__row "+((i+1)%2===0?'even':(i+1)%3===0?'odd oddRight':'odd oddLeft')}>
                            <div className="galleryItem" onClick={() => {setSlide(file);setLightbox(!lightbox)}}>
                                {(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file)?<img src={STORAGEURL+file} alt="položka galerie"/>: <div className="video"><ReactPlayer url={STORAGEURL+file} /></div>}
                            </div>
                        </div>
                    ))||<Loading />}
                </div>
                {srcs&&<FsLightbox
                    toggler={lightbox}
                    sources={srcs}
                    source={STORAGEURL+slide}
                />}
            </>}
        </>}
        </Layout>
    )
}

export default Media
