import Layout from "./Layout"
import pic from "./assets/img/illu/terminypic.png";

const Kontakty = () => {
    return (
        <Layout nested={1} className="base kontakt">
            <img className="pickontakty" src={pic} />
            <div className="base__header smaller">
                <div className="base__header__right">
                    <h2>Kontakty</h2>

                    <p>
                        Festival Poznej Vltavu je dílem úzké spolupráce organizací zřízených Magistrátem hlavního města Prahy. Vyplaval vám na povrch dotaz týkající se jeho organizace? Napište nám na <a href='mailto:festival@kreativnipraha.eu' className="inline">festival@poznejvltavu.eu</a>. Rádi vám jej zodpovíme. 
                    </p>
                </div>
            </div>
            <h3>Pořadatel</h3>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <strong>Kreativní Praha, z. ú.</strong>
                    <span>U Radnice 10</span>
                    <span>110 00 Praha 1 - Staré město</span>
                </div>
                <div className="kontakty__kontakt">
                    <strong>Lenka Hluchá</strong>
                    <span>vedoucí produkce </span>
                </div>
                <div className="kontakty__kontakt">
                    <strong>Ondřej Horák</strong>
                    <span> programový vedoucí</span>
                </div> 
                <div className="kontakty__kontakt">
                    <strong>IPR Praha </strong>
                    <span>Vyšehradská 57/2077</span>
                    <span>128 00 Praha 2 ‒ Nové Město</span>
                </div>
                <div className="kontakty__kontakt">
                    <strong>Marek Kundrata</strong>
                    <span>ideový vedoucí</span>
                </div>
            </div>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <span>Vaše dotazy či výzvy směřujte na email a my Vám rádi odpovíme: <a href='mailto:festival@kreativnipraha.eu' className="inline">festival@kreativnipraha.eu</a></span>
                </div>
            </div>
        </Layout>
    )
}

export default Kontakty