import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import { APIURL } from ".";
import Loading from "./components/UI/Loading";

const Zastity = () => {
    const [zastity, setZastity] = useState();
    const [zastityLoading, setZastityLoading] = useState(true);
    const fetchZastity = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: "zastity",
                orderAsc: "ASC",
                limit: 100,
                page: 1,
                language: "cs",
            })
            .then((response) => {
                setZastity(response.data.body);
                setZastityLoading(false);
            });
    };

    useEffect(() => {
        fetchZastity();
    }, []);

    return (
        <Layout nested={1} className="base zastity">
            <div className="base__header">
                <div className="base__header__right">
                    <h2>Záštity</h2>

                    <p>
                        Ambicí festivalu Poznej Vltavu je nadchnout pro naši řeku celou Prahu. Jménem celého organizačního týmu proto institucím, které nad festivalem převzaly záštitu, upřímně děkujeme.
                    </p>
                </div>
            </div>
            {zastityLoading ? (
                <Loading />
            ) : (
                <>
                    {zastity && (
                        <div className="zastity__box">
                            {zastity?.items.map((p) => (
                                <div className="zastity__box__container">
                                    <a href={p.url_zastity} target="_blank">
                                        <img src={p.logo} alt={p.alt} />
                                    </a>
                                    <span>{p.intro_text}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </Layout>
    );
};

export default Zastity;
