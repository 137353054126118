import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import Layout from "./Layout";
import axios from "axios";
import { APIURL } from ".";
import Loading from "./components/UI/Loading";
import { Link } from "react-router-dom";
import vlajka from "./assets/img/illu/partneripic2.png";
import mapapic2 from "./assets/img/illu/mapapic2.png";
import mapapic from "./assets/img/illu/mapapic.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules"

import 'swiper/scss';
import 'swiper/scss/navigation';


const Vzdelavani = () => {
    const [pageData, setPageData] = useState()
    const [pageDataLoading, setPageDataLoading] = useState(true) 
    const fetchPage = async () => {
        await axios
            .post(APIURL + "/page", {
                name: 'vzdelavani', 
                language: "cs", 
            })
            .then((response) => {
                setPageData(response.data.body);
                setPageDataLoading(false) 
            });
    } 
   
    useEffect(() => {
        fetchPage()
    }, [])
    return (
        <Layout nested={1} className="base vzdelavani">
            {(pageDataLoading) ? <Loading /> : <>
                <div className="base__header">
                    <img src={mapapic2} className="budova" />
                    <div className="base__header__right">
                        <h2>{parse(pageData.intro_title)}</h2>
                        <p>
                            {parse(pageData.intro_text)}
                        </p>
                    </div>
                    <img src={vlajka} className="vlajka" />
                </div>
            

                <section className="vzdelavani__events">
                    <span style={{textAlign:'center'}}>{parse(pageData.workshop_desc)}</span>
                    <div>
                        <div className="events__container"> 
                            {pageData?.workshop_items?.map((event, i) => (
                                <React.Fragment key={i}>
                                    <div className="event">
                                        <div className="event__header">
                                            {event.banner ? <img src={event.banner} alt={"Obrázek události"} /> : <img src="/demo.png" />}
                                        </div>
                                        <div className="event__body">
                                            <h2>{event.name}</h2>
                                            <p>
                                                {event.subname}
                                            </p>
                                            <p>
                                                {event.content}
                                            </p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="gallery">  
                    <div className="gallery__box">
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            autoplay={{ delay: 5000 }}
                            loop={true}
                            navigation={true}
                            slide
                            spaceBetween={25}
                            slidesPerView={window.innerWidth>1000?3:1}
                        > 
                            {pageData.workshop_gallery.split("~").map((d,i) => (<SwiperSlide key={i}><img src={d} alt="obrázek galerie" /></SwiperSlide>))}
                        </Swiper>
                    </div> 
                    {/* <Link
                        className="button transWhite"
                        target="_blank"
                        to="/mapa_poznejVltavu.webp"
                    >
                        Zobrazit fotodokumentaci
                    </Link> */}
                </section>

                <section className="basic festival">
                    <h2>{parse(pageData.method_title)}</h2>
                    <p>
                        {parse(pageData.method_desc)}                
                    </p>
                    <Link
                        className="button transWhite"
                        target="_blank" 
                        to={parse(pageData.method_button_file)}   
                    >
                        {parse(pageData.method_button_text)} 
                    </Link>
                    <img src={mapapic} className="zamek" />
                </section>
                <section className="loga">
                    {pageData?.outro_actors&&
                        <div className="loga__box">
                            {pageData?.outro_actors.map((p, i) => (
                                <a href={p.url_aktera} key={i} target="_blank"><img src={p.logo} alt="logo" /></a>
                            ))} 
                        </div>
                    }
                    <span>{parse(pageData.outro_text)}</span>
                </section>
            </>}
        </Layout>
    );
};

export default Vzdelavani;
