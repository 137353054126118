import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import { APIURL } from ".";
import Loading from "./components/UI/Loading";


const Partneri = () => {
    const [firstData, setFirstData] = useState()
    const [firstDataLoading, setFirstDataLoading] = useState(true)
    const fetchAkteriFirst = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'loga_akteru',
                orderAsc: "ASC",
                limit: 100,
                page: 1,
                language: "cs",
                filter: [{ name: "level", value: "1. úroveň" }],
            })
            .then((response) => {
                setFirstData(response.data.body);
                setFirstDataLoading(false)
            });
    }

    const [secondData, setSecondData] = useState()
    const [secondDataLoading, setSecondDataLoading] = useState(true)
    const fetchAkteriSecond = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'loga_akteru',
                orderAsc: "ASC",
                limit: 100,
                page: 1,
                language: "cs",
                filter: [{ name: "level", value: "2. úroveň" }],
            })
            .then((response) => {
                setSecondData(response.data.body);
                setSecondDataLoading(false)
            });
    }

    const [mediaData, setMediaData] = useState()
    const [mediaDataLoading, setMediaDataLoading] = useState(true)
    const fetchAkteriMedia = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'loga_akteru',
                orderAsc: "ASC",
                limit: 100,
                page: 1,
                language: "cs",
                filter: [{ name: "level", value: "media" }],
            })
            .then((response) => {
                setMediaData(response.data.body);
                setMediaDataLoading(false)
            });
    }

    useEffect(() => {
        fetchAkteriFirst()
        fetchAkteriSecond()
        fetchAkteriMedia()
    }, [])

    return (
        <Layout nested={1} className="base akteri">
            <div className="base__header">
                <div className="base__header__right">
                    <h2>Aktéři</h2>

                    <p>
                        Bez obětavé podpory našich partnerů by náš festival byl pouhou kapkou v moři. Za jejich přízeň jim tímto srdečně děkujeme.
                    </p>
                </div>
            </div>
            {(firstDataLoading && secondDataLoading && mediaDataLoading) ? <Loading /> :
                <>
                    <div className="akteri__box">
                        {firstDataLoading ? <Loading /> : (
                            firstData?.items.map(p => (
                                <a href={p.url_aktera} target="_blank"><img src={p.logo} alt={p.alt} /></a>
                            )))}
                    </div>

                    <div className="akteri__box">
                        {secondDataLoading ? <Loading /> : (secondData?.items.map(p => (
                            <a href={p.url_aktera} target="_blank"><img src={p.logo} alt={p.alt} /></a>
                        )))}
                    </div>

                    <h3>MEDÁLNÍ PARTNER </h3>
                    <div className="akteri__box">
                        {mediaDataLoading ? <Loading /> : (mediaData?.items.map(p => (
                            <a href={p.url_aktera} target="_blank"><img src={p.logo} alt={p.alt} /></a>
                        )))}
                    </div>
                </>
            }

        </Layout>
    );
};

export default Partneri;
